import React, {FC, useEffect, useMemo, useState} from 'react';
import {Alert, Card, CardBody, Container} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import {axiosApi} from '../../helpers/Axios';
import {useNavigate} from "react-router-dom";


const List: FC = () => {
    const navigate = useNavigate();

    const breadcrumbItems: {
        title: string;
        link: string;
    }[] = [
        {title: 'Sharebee', link: '/'},
        {title: 'Szablony wiadomości', link: '/message/message-templates'},
    ];


    const columns = useMemo(
        () => [
            {
                Header: 'Nazwa',
                accessor: 'name',
                disableFilters: false,
                filterable: true,
            },
            {
                Header: 'Tytuł',
                accessor: 'title',
                disableFilters: false,
                filterable: true,
            },
            {
                Header: 'Język',
                accessor: 'language',
                disableFilters: false,
                filterable: true,
            },
            {
                Header: 'Akcje',
                accessor: 'id',
                disableFilters: true,
                filterable: false,
                Cell: (props: any) => (
                    <div>
                        <button
                            className="btn btn-primary btn-sm m-lg-1"
                            onClick={() => navigate(`/message/message-templates/send/${props.value}`)}
                        >
                            <i className={'fa fa-envelope'}/>
                        </button>
                        <button
                            className="btn btn-warning btn-sm m-lg-1"
                            onClick={() => navigate(`/message/message-templates/edit/${props.value}`)}
                        >
                            <i className={'fa fa-cogs'}/>
                        </button>
                        <button
                            className="btn btn-danger btn-sm m-lg-1"
                            onClick={undefined}
                        >
                            <i className={'fa fa-trash'}/>
                        </button>
                    </div>
                ),
            },
        ],
        [],
    );
    const [templates, setTemplates] = useState<MessageTemplate[]>([]);

    useEffect(() => {
        axiosApi
            .get('message/message-templates', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.token,
                },
            })
            .then(result => setTemplates(result.data));
    }, []);

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Szablony wiadomości" breadcrumbItems={breadcrumbItems}/>
                <Card>
                    <CardBody>
                        <TableContainer
                            columns={columns || []}
                            data={templates}
                            customPageSize={10}
                            className={undefined}
                            isGlobalFilter={true}
                            isJobListGlobalFilter={undefined}
                            isAddOptions={true}
                            isAddUserList={undefined}
                            handleAddClick={() => navigate('/message/message-templates/add')}
                            handleUserClick={undefined}
                            handleCustomerClick={undefined}
                            isAddCustList={undefined}
                            customPageSizeOptions={undefined}
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};
export default List;
