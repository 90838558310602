import React, {FC, useEffect, useMemo, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Input, Label, Row} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {useParams} from "react-router-dom";
import {axiosApi} from "../../helpers/Axios";
import Select, {GroupBase, Options} from 'react-select';
import toastr from 'toastr';
import {useNavigate} from "react-router-dom";

type OptionType = { value: string; label: string };

const languages: Options<OptionType> = [
    {value: 'pl', label: 'Polski'},
    {value: 'en', label: 'Angielski'},
];

const Form: FC = () => {
    const navigate = useNavigate();

    const {id} = useParams<string>();
    const [form, setForm] = useState<MessageTemplate>({
        id: null,
        name: '',
        title: '',
        body: '',
        language: 'pl',
    });

    useEffect(() => {
        if (!id) {
            return;
        }

        axiosApi
            .get(`message/message-templates/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.token,
                },
            })
            .then(result => setForm(result.data));

    }, [id]);

    const save = (): void => {
        if (id) {
            axiosApi
                .put(`message/message-templates/${id}`, form, {
                    headers: {Authorization: 'Bearer ' + sessionStorage.token},
                })
                .then(() => toastr.success('Zapisano.'));
        } else {
            axiosApi
                .post('message/message-templates', form, {
                    headers: {Authorization: 'Bearer ' + sessionStorage.token},
                })
                .then((result) => {
                    toastr.success('Zapisano.')
                    navigate(`/message/message-templates/edit/${result.data.id}`);
                });

        }
    };

    const breadcrumbItems: {
        title: string;
        link: string;
    }[] = [
        {title: 'Sharebee', link: '/'},
        {title: 'Szablony wiadomości', link: '/message/message-templates'},
        {title: form.name ?? '', link: '/message/message-templates'},

    ];

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs title="Szablon wiadomości" breadcrumbItems={breadcrumbItems}/>
                <Card>
                    <CardBody>
                        <form>
                            <Row>
                                <Col lg="4">
                                    <Label className="form-label">Nazwa</Label>
                                    <Input type="text" value={form.name ?? undefined}
                                           onChange={e => setForm({...form, name: e.target.value})}
                                           placeholder="Nazwa"/>
                                </Col>

                                <Col lg="4">
                                    <div className="mb-3">
                                        <Label className="form-label">Tytuł</Label>
                                        <Input type="text" value={form.title ?? undefined}
                                               onChange={e => setForm({...form, title: e.target.value})}
                                               placeholder="Tytuł"/>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="mb-3">
                                        <Label className="form-label">Język</Label>
                                        <Select<OptionType, false, GroupBase<OptionType>>
                                            onChange={(selectedOption) => setForm({
                                                ...form,
                                                language: (selectedOption ? selectedOption.value : null)
                                            })}
                                            options={languages}
                                            classNamePrefix="select2-selection"
                                            value={languages.find(language => language.value === form.language)}
                                        />
                                    </div>
                                </Col>

                                <Col lg="12">
                                    <Label className="form-label">Zawartość</Label>
                                    <Input type="textarea" value={form.body ?? undefined}
                                           onChange={e => setForm({...form, body: e.target.value})}
                                           placeholder="Zawartość" rows="20"/>
                                </Col>

                                <Col lg="12">
                                    <Button color="success" className="mt-2" style={{float: "right"}} onClick={save}>
                                        Zapisz
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};
export default Form;
