import React, { FC, useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { axiosApi } from '../../helpers/Axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import InstanceSelectorMultiple from '../../components/Instance/InstanceSelectorMultiple';
import WebinarSelector from '../../components/Webinar/WebinarSelector';

const WebinarRegistration: FC = () => {
  const breadcrumbItems: {
    title: string;
    link: string;
  }[] = [
    { title: 'Sharebee', link: '/' },
    { title: 'Rejestracja na webinar', link: '/report/webinar-registrations' },
  ];

  const [email, setEmail] = useState<string>('');
  const [selectedInstances, setSelectedInstances] = useState<string[]>([]);
  const [selectedWebinar, setSelectedWebinar] = useState<string>('');

  const send = () => {
    axiosApi
      .post(
        '/webinar/registrations-report',
        { email: email, instances: selectedInstances, webinarId: selectedWebinar },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.token,
          },
        },
      )
      .then(result => toastr.success('Zlecenie generowania zostało wysłane do realizacji.'));
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="NPS" breadcrumbItems={breadcrumbItems} />
        <Card>
          <CardBody>
            <InstanceSelectorMultiple onChange={setSelectedInstances} />
            <WebinarSelector onChange={setSelectedWebinar} />
            <Row>
              <Col lg="12" className={'mb-2 mt-2'}>
                <h3>Podaj adres e-mail</h3>
              </Col>
              <Col lg="12">
                <Input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="E-mail" />
              </Col>
              <Col lg="12">
                <Button color="success" className="mt-2" onClick={send}>
                  Generuj
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
export default WebinarRegistration;
