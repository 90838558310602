import React, { FC, useEffect, useState } from 'react';
import { Badge, Col, Input, Label, Row } from 'reactstrap';
import { axiosApi } from '../../helpers/Axios';
import 'toastr/build/toastr.min.css';

interface InstanceSelectorProps {
  onChange: (instances: string[]) => void;
  activeSelected?: boolean;
}

const InstanceSelectorMultiple: FC<InstanceSelectorProps> = ({ onChange, activeSelected }) => {
  const [instances, setInstances] = useState<Instance[]>([]);
  const [selectedInstances, setSelectedInstances] = useState<string[]>([]);

  const selectInstance = (instance: string) => {
    if (selectedInstances.indexOf(instance) !== -1) {
      setSelectedInstances(prevState => prevState.filter(item => instance !== item));

      return;
    }
    setSelectedInstances(prevState => [...prevState, instance]);
  };

  useEffect(() => {
    onChange(selectedInstances);
  }, [selectedInstances]);

  useEffect(() => {
    axiosApi
      .get('instances', {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.token,
        },
      })
      .then(result => setInstances(result.data));
  }, []);

  useEffect(() => {
    setSelectedInstances([]);

    if (!activeSelected) {
      return;
    }

    instances.map(instance => (instance.active ? setSelectedInstances(prevState => [...prevState, instance.appName ?? '']) : null));
  }, [instances]);

  return (
    <>
      <Row>
        <Col lg="12" className={'mb-2'}>
          <h3>Wybierz instancje</h3>
        </Col>
      </Row>
      <Row>
        {instances.map((instance, index) => (
          <Col lg="3">
            <div className="form-check form-switch mb-3">
              <Input
                type="checkbox"
                className="form-check-input theme-choice"
                id={`checkbox_${index}`}
                checked={selectedInstances.indexOf(instance.appName ?? '') !== -1}
                onChange={() => selectInstance(instance.appName ?? '')}
              />
              <Label className="form-check-label" htmlFor={`checkbox_${index}`}>
                {instance.name} ({instance.appName}){instance.active ? <Badge color="success">A</Badge> : <Badge color="danger">N</Badge>}
              </Label>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};
export default InstanceSelectorMultiple;
