import React from 'react';
import { Navigate } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Dashboard from '../pages/Dashboard';
import InstanceList from '../pages/Instance/List';
import NPSView from '../pages/Report/NPS';
import WebinarRegistrationView from '../pages/Report/WebinarRegistration';
import MessageTemplateList from '../pages/MessageTemplate/List';
import MessageTemplateForm from '../pages/MessageTemplate/Form';
import MessageTemplateSend from '../pages/MessageTemplate/Send';
import MessageSend from '../pages/Message/Send';

const authProtectedRoutes = [
  { path: '/instances', component: <InstanceList /> },
  { path: '/report/nps', component: <NPSView /> },
  { path: '/report/webinar-registrations', component: <WebinarRegistrationView /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/message/message-templates', component: <MessageTemplateList /> },
  { path: '/message/message-templates/add', component: <MessageTemplateForm /> },
  { path: '/message/message-templates/edit/:id', component: <MessageTemplateForm /> },
  { path: '/message/message-templates/send/:id', component: <MessageTemplateSend /> },
  { path: '/message/send', component: <MessageSend /> },
  { path: '/', exact: true, component: <Navigate to="/instances" /> },
];

const publicRoutes = [
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
