import React, { FC, useEffect, useState } from 'react';
import { Badge, Col, Input, Label, Row } from 'reactstrap';
import { axiosApi } from '../../helpers/Axios';
import 'toastr/build/toastr.min.css';
import 'dayjs/locale/pl';
import HumanizedDate from '../Date/HumanizedDate';

interface TemplateSelectorProps {
    onChange: (webinar: string) => void;
}

const TemplateSelector: FC<TemplateSelectorProps> = ({ onChange }) => {
    const [templates, setTemplates] = useState<MessageTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');

    useEffect(() => {
        axiosApi
            .get('message/message-templates', {
                headers: {
                    Authorization: 'Bearer ' + sessionStorage.token,
                },
            })
            .then(result => setTemplates(result.data));
    }, []);

    useEffect(() => {
        onChange(selectedTemplate);
    }, [selectedTemplate]);

    useEffect(() => {
        setSelectedTemplate((templates?.[0]?.id)?.toString() || '');
    }, [templates]);

    return (
        <>
            <Row>
                <Col lg="12" className={'mb-2'}>
                    <h3>Wybierz szablon wiadomości</h3>
                </Col>
            </Row>
            <Row>
                <Col lg="12" className={'mb-2'}>
                    <select
                        id="custom-select"
                        className="form-select"
                        value={selectedTemplate}
                        onChange={e => {
                            setSelectedTemplate(e.target.value || '');
                        }}
                    >
                        {templates.map(option => (
                            <option key={option.id} value={option.id || ''}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </Col>
            </Row>
        </>
    );
};
export default TemplateSelector;
