import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';

export const Filter = ({ column }) => {
  return <div style={{ marginTop: 5 }}>{column.canFilter && column.render('Filter')}</div>;
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
  return (
    <Input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Szukaj (${length}) ...`}
    />
  );
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, filterOptions } }) => {
  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Wszystkie</option>
      {filterOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export const AccountColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      if (!(row.values.caregivers ?? []).length) {
        return;
      }

      row.values.caregivers.map(item => options.add(item.username));
    });
    return [...options.values()];
  }, [preFilteredRows]);

  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Wszystkie</option>
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const ContractColumnFilter = ({ column: { setEnding, filterValue, setFilter } }) => {
  const [value, setValue] = useState(filterValue ? 'ending' : '');

  useEffect(() => {
    if (setEnding < 1) {
      return;
    }

    setValue('ending');
  }, [setEnding]);

  useEffect(() => {
    if (!value) {
      setFilter(null);
      return;
    }

    const threeMonths = new Date();

    threeMonths.setMonth(threeMonths.getMonth() + 3);

    setFilter([new Date().setYear(2010), threeMonths]);
  }, [value]);

  return (
    <select id="custom-select" className="form-select" value={value} onChange={e => setValue(e.target.value)}>
      <option value="">Wszystkie</option>
      <option value="ending">Kończące się</option>
    </select>
  );
};

export const dateBetweenFilterFn = (rows, id, filterValues) => {
  const sd = filterValues?.[0];
  const ed = filterValues?.[1];

  if (!ed && !sd) {
    return rows;
  }

  return rows.filter(r => {
    const cellDate = new Date(r.values[id]);

    if (ed && sd) {
      return cellDate >= sd && cellDate <= ed;
    }

    if (sd) {
      return cellDate >= sd;
    }

    if (ed) {
      return cellDate <= ed;
    }
  });
};

export const accountFilterFn = (rows, id, filterValues) => {
  if (!filterValues) {
    return;
  }

  return rows.filter(r => {
    return r.values[id].filter(account => account.username === filterValues).length;
  });
};
