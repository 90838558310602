import React, {FC, useEffect, useState} from 'react';
import {Col, Input, Label, Row} from 'reactstrap';

interface TemplateOptionsProps {
    onChange: (options: string[]) => void;
}

const allOptions = ['senderName', 'senderEmail', 'replyTo'];

const TemplateOptions: FC<TemplateOptionsProps> = ({onChange}) => {
    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        onChange(options);
    }, [options]);

    return (
        <>
            <Row>
                <Col lg="12">
                    <h5 className={'mt-4'}>Opcje</h5>
                    <Row className={'mt-2'}>
                        {allOptions.map((variable, index) => (
                            <Col lg="4" key={index}>
                                <Label className="form-label mt-2">{variable}</Label>
                                <Input type="text" value={options[variable] ?? ''}
                                       onChange={e => setOptions({
                                           ...options,
                                           [variable]: e.target.value
                                       })}
                                       placeholder={variable}/>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </>
    );
};
export default TemplateOptions;
