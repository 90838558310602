import React, { FC, useEffect, useMemo, useState } from 'react';
import { Alert, Card, CardBody, Container } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { axiosApi } from '../../helpers/Axios';
import classnames from 'classnames';
import {
  AccountColumnFilter,
  accountFilterFn,
  ContractColumnFilter,
  dateBetweenFilterFn,
  SelectColumnFilter,
} from '../../components/Common/filters';


const List: FC = () => {
  const breadcrumbItems: {
    title: string;
    link: string;
  }[] = [
    { title: 'Sharebee', link: '/' },
    { title: 'Instancje', link: '/instances' },
  ];

  const [ending, setEnding] = useState<number>(0);

  const columns = useMemo(
    () => [
      {
        Header: 'Aktywny',
        id: 'active',
        accessor: 'active',
        Cell: ({ row }) => {
          return (
            <div className="font-size-20 text-center" style={{ margin: '0 auto' }}>
              <i
                className={classnames('mdi', {
                  'mdi-check-circle text-success': row.original.active === true,
                  'mdi-minus-circle text-danger': row.original.active !== true,
                })}
              />
            </div>
          );
        },
        disableFilters: false,
        filterable: true,
        Filter: SelectColumnFilter,
        filterOptions: [
          { value: true, label: 'Tak' },
          { value: false, label: 'Nie' },
        ],
      },
      {
        Header: 'Nazwa',
        accessor: 'name',
        Cell: ({ row }) => {
          return (
            <>
              <div>
                {row.original.name && <>{row.original.name}</>}
                {!row.original.name && <>&nbsp;</>}
              </div>
              <small>{row.original.appName}</small>
            </>
          );
        },
        disableFilters: false,
        filterable: true,
        filter: (rows: { original: Instance }[], id: string, filterValues: string | null): any[] | void => {
          if (!filterValues) {
            return;
          }
          return rows.filter((r: { original: Instance }) => {
            return (r.original.name || '').includes(filterValues) || (r.original.appName || '').includes(filterValues);
          });
        },
      },
      {
        Header: 'Klasa',
        accessor: 'class',
        Cell: ({ row }) => {
          return (
            <div className="text-center" style={{ margin: '0 auto' }}>
              {row.original.class}
            </div>
          );
        },
        Filter: SelectColumnFilter,
        filterOptions: [
          { value: 'A', label: 'A' },
          { value: 'B', label: 'B' },
          { value: 'C', label: 'C' },
          { value: 'D', label: 'D' },
        ],
      },
      {
        Header: 'URL',
        accessor: 'url',
        Cell: ({ row }) => {
          return (
            <>
              <a target={'_blank'} href={row.original.url}>
                {row.original.url}
              </a>
              <a style={{ float: 'right' }} target={'_blank'} href={row.original.url + '/agency'}>
                <i
                  style={{ marginLeft: '20px', position: 'relative', bottom: '-3px' }}
                  className={classnames('mdi', 'font-size-20', 'mdi-microsoft')}
                />
                Login
              </a>
            </>
          );
        },
        disableFilters: false,
        filterable: true,
      },
      {
        Header: 'Opiekunowie',
        accessor: 'caregivers',
        Cell: ({ row }) => {
          return (row.original.caregivers ?? []).map(item => (
            <div>
              <a href={'mailto:' + item.email}>
                <img className="rounded-circle header-profile-user me-1" src={item.avatar} alt="" />
                <span className="d-none d-xl-inline-block ms-1 text-transform">{item.username}</span>
              </a>
            </div>
          ));
        },
        disableFilters: false,
        filterable: true,
        Filter: AccountColumnFilter,
        filter: accountFilterFn,
      },
      {
        Header: 'Data końca umowy',
        accessor: 'contractEndAt',
        disableFilters: false,
        filterable: true,
        Filter: ContractColumnFilter,
        filter: dateBetweenFilterFn,
        setEnding: ending,
      },
      {
        Header: 'Limit użytkowników',
        accessor: 'usersLimit',
        disableFilters: true,
        filterable: false,
      },
      {
        Header: 'Liczba użytkowników',
        accessor: 'usersActive',
        disableFilters: true,
        filterable: false,
      },
      {
        Header: 'Wszyscy użytkownicy',
        accessor: 'usersAll',
        disableFilters: true,
        filterable: false,
      },
    ],
    [ending],
  );
  const [instances, setInstances] = useState<Instance[]>([]);

  useEffect(() => {
    axiosApi
      .get('instances', {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.token,
        },
      })
      .then(result => setInstances(result.data));
  }, []);

  const shortContractTerm = useMemo<number>((): number => {
    const threeMonths = new Date();

    threeMonths.setMonth(threeMonths.getMonth() + 3);

    return instances.filter(item => item.contractEndAt && new Date(item.contractEndAt) < threeMonths).length;
  }, [instances]);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Instancje" breadcrumbItems={breadcrumbItems} />
        <Card>
          <CardBody>
            {shortContractTerm && (
              <Alert color={'danger'}>
                <b>{shortContractTerm} klientów</b> kończy umowę w ciągu 3 miesięcy.{' '}
                <a href="#" onClick={() => setEnding(prevState => prevState + 1)} className="alert-link">
                  Zobacz kończące się umowy
                </a>
                .
              </Alert>
            )}
            <TableContainer
              columns={columns || []}
              data={instances}
              customPageSize={10}
              className={undefined}
              isGlobalFilter={true}
              isJobListGlobalFilter={undefined}
              isAddOptions={undefined}
              isAddUserList={undefined}
              handleAddClick={undefined}
              handleUserClick={undefined}
              handleCustomerClick={undefined}
              isAddCustList={undefined}
              customPageSizeOptions={undefined}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
export default List;
